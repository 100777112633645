import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import t from '../../lib/translate/index';
import themeOptions from '../react-select/theme';
import { useFormSubmit } from '../../lib/service/formSubmit/useFormSubmit';

const ContactFormDetective = ({
  locale,
  privacyLink,
  product,
  name,
}) => {
  const privacyFieldLbl = t(
    'form_productinquiry_privacy_label',
    `<a href="${privacyLink}" target="_blank" rel="noreferrer">${t('form_productinquiry_privacy_link_label')}</a>`,
  );

  const genderOptions = t('form_productinquiry_salutation_values')
    .split(',')
    .map((opt) => ({ value: opt, label: opt }));

  const formSubmit = useFormSubmit(locale, 'product-contact');

  const onSubmit = async ({ privacy, ...data }, { setSubmitting }) => {
    setSubmitting(true);

    const validationErrors = await formSubmit.mutateAsync({
      ...data,
      privacy: privacy === true ? 1 : -1,
    });

    setSubmitting(false);

    if (!validationErrors) {
      // no validation errors, we're done here
      const data = {
        event: 'submit_produkt_anfragen',
        ecommerce: {
          value: product?.price_without_tax, // Nettowert des Produkts bzw. bei Quantity > 1 Gesamtwert der Produkte
          currency: "EUR",
          items: [
            {
              item_id: product?.sku,
              item_name: name,
              index: 0, // Da Produkte einzeln in den Warenkorb gelegt werden, einfach 0 lassen.
              item_brand: product?.cmsBrand?.name,
              price: product?.price_without_tax, // Netto-Preis des Produkts bei Quantität 1
              quantity: 1,
            }],
        }
      };

      if(product?.categories) {
        data.ecommerce.items[0].item_category = product?.categories[1]?.name; // optional (skipping Index 0 as it is always "Juwelier Roller"
        data.ecommerce.items[0].item_category2 = product?.categories[2]?.name; // optional
        data.ecommerce.items[0].item_category3 = product?.categories[3]?.name; // optional
        data.ecommerce.items[0].item_category4 = product?.categories[4]?.name; // optional
        data.ecommerce.items[0].item_category5 = product?.categories[5]?.name; // optional
      }

      window?.dataLayer?.push(data);
      return;
    }

    // validation errors

    const validationMsg = Object.keys(validationErrors)
      .reduce((carry, key) => {
        carry.push(`${key}: ${validationErrors[key].join(' | ')}`);
        return carry;
      }, []).join('\n');

    // eslint-disable-next-line no-alert
    alert(validationMsg);
  };

  return (
    <>
      {formSubmit.isSuccess && (
        <>
          <p>{t`form_productinquiry_submit_success_txt`}</p>

          <footer className="form-footer">
            <div className="inner">
              <button type="submit" disabled className="btn btn-primary">{t`form_productinquiry_submit_button_label`}</button>
              <button type="button" className="btn btn-outline" onClick={() => navigate(-1)}>
                {t`form_productinquiry_back_button_label`}
              </button>
            </div>
          </footer>
        </>
      )}

      {formSubmit.isLoading && (
        <>
          <span>LOADING...</span>
          <footer className="form-footer">
            <div className="inner">
              <button type="submit" disabled className="btn btn-primary">{t`form_contact_submit_button_label`}</button>
              <button type="button" disabled className="btn btn-outline">{t`form_contact_back_button_label`}</button>
            </div>
          </footer>
        </>
      )}

      {!formSubmit.isLoading && !formSubmit.isSuccess && (
        <Formik
          initialValues={{
            gender: '',
            title: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            itemnumber: product?.sku,
            manufacturerreferencenumber: product?.jr_manufacturer_number,
            message: '',
            privacy: false,
          }}
          validationSchema={Yup.object().shape({
            gender: Yup.string()
              .oneOf([genderOptions.map(({ value: v }) => v)], t`validation_err_required`)
              .required(t`validation_err_required`),
            title: Yup.string(),
            firstname: Yup.string().required(t`validation_err_required`),
            lastname: Yup.string().required(t`validation_err_required`),
            email: Yup.string().email(t`validation_err_email`).required(t`validation_err_required`),
            phone: Yup.string(),
            itemnumber: Yup.string().required(t`validation_err_required`),
            manufacturerreferencenumber: Yup.string().required(t`validation_err_required`),
            message: Yup.string().required(t`validation_err_required`),
            privacy: Yup.boolean().oneOf([true], t`validation_err_required`).required(t`validation_err_required`),
          })}
          onSubmit={onSubmit}
        >
          {({
            values,
            setFieldValue,
          }) => (
            <Form className="needs-validation">
              <p className="required-info">{t`form_required_fields`}</p>

              <div className="form-group">
                <label htmlFor="salutation">
                  {t`form_productinquiry_salutation_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Select
                  name="gender"
                  value={(genderOptions.find((opt) => opt.value === values.gender))}
                  onChange={(opt) => { setFieldValue('gender', opt.value); }}
                  options={genderOptions}
                  theme={themeOptions()}
                  placeholder={t`form_productinquiry_salutation_placeholder`}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
                <ErrorMessage name="gender">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="title">{t`form_productinquiry_title_label`}</label>
                <Field className="form-control" type="text" name="title" id="title" placeholder={t`form_productinquiry_title_placeholder`} />
                <ErrorMessage name="title">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="firstname">
                  {t`form_productinquiry_surname_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="text" name="firstname" id="firstname" placeholder={t`form_productinquiry_surname_placeholder`} />
                <ErrorMessage name="firstname">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="lastname">
                  {t`form_productinquiry_lastname_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="text" name="lastname" id="lastname" placeholder={t`form_productinquiry_lastname_placeholder`} />
                <ErrorMessage name="lastname" className="invalid-feedback">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="email">
                  {t`form_productinquiry_email_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="email" name="email" id="email" placeholder={t`form_productinquiry_email_placeholder`} />
                <ErrorMessage name="email">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="phone">{t`form_productinquiry_phone_label`}</label>
                <Field className="form-control" type="phone" name="phone" id="phone" placeholder={t`form_productinquiry_phone_placeholder`} />
              </div>

              <div className="form-group">
                <label htmlFor="itemnumber">
                  {t`form_productinquiry_sku_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="text" disabled={product?.sku || null} name="itemnumber" id="itemnumber" placeholder={t`form_productinquiry_sku_placeholder`} />
                <ErrorMessage name="itemnumber">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="manufacturerreferencenumber">
                  {t`form_productinquiry_manufacturer_reference_number_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="text" disabled={product?.jr_manufacturer_number || null} name="manufacturerreferencenumber" id="manufacturerreferencenumber" placeholder={t`form_productinquiry_manufacturer_reference_number_placeholder`} />
                <ErrorMessage name="manufacturerreferencenumber">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <label htmlFor="message">
                  {t`form_productinquiry_message_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" name="message" as="textarea" rows="3" id="message" placeholder={t`form_productinquiry_message_placeholder`} />
                <ErrorMessage name="message">{ (msg) => <p>{msg}</p>}</ErrorMessage>
              </div>

              <div className="form-group">
                <div className="form-check">
                  <Field className="form-check-input" name="privacy" type="checkbox" id="privacy" />
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line react/no-danger,jsx-a11y/label-has-associated-control */}
                  <label className="form-check-label" htmlFor="privacy" dangerouslySetInnerHTML={{ __html: privacyFieldLbl }} />
                  <ErrorMessage name="privacy" id="privacy">{ (msg) => <p>{msg}</p>}</ErrorMessage>
                </div>
              </div>

              <footer className="form-footer">
                <div className="inner">
                  <button type="submit" className="btn btn-primary">{t`form_productinquiry_submit_button_label`}</button>
                  <button type="button" className="btn btn-outline" onClick={() => navigate(-1)}>
                    {t`form_productinquiry_back_button_label`}
                  </button>
                </div>
              </footer>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
ContactFormDetective.defaultProps = {
  product: undefined,
  name: '',
};
ContactFormDetective.propTypes = {
  locale: PropTypes.string.isRequired,
  privacyLink: PropTypes.string.isRequired,
  product: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
};
export default ContactFormDetective;
