// see https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/adding-gatsby-image-support/

import React from 'react';
import { GatsbyImage, getImageData } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const urlBuilder = ({ baseUrl, width }) => `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}width=${Math.round(width * 1.75)}`;

const getProductImageData = ({ image, ...props }) => getImageData({
  baseUrl: image,
  urlBuilder,
  pluginName: 'gatsby-source-jroller',
  ...props,
});

const Image = ({
  image,
  ...props
}) => {
  const imageData = getProductImageData({ image, ...props });
  if (!image || !imageData) {
    // eslint-disable-next-line no-console
    console.log('product image not defined');
    return null;
  }
  return (<GatsbyImage image={imageData} {...props} />);
};

Image.propTypes = {
  image: PropTypes.string.isRequired,
};

export default Image;
