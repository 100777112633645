import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import FormLayout from '../layouts/form';

import ProductImage from '../components/shop-product/image/index';
import ContactFormDetective from '../components/contact-form-detective/index';

const Detective = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      metaRobots,
      metaCanonical,
      header: headers,
    },
    datoCmsConfig: {
      pagePrivacy,
    },
  },
  pageContext: { lang: locale },
  location,
}) => {
  const {
    name,
    product,
  } = (location?.state || {});

  return (
    <FormLayout
      seoTags={seoMetaTags}
      metaRobots={metaRobots}
      metaCanonical={metaCanonical}
      headline={headers?.length > 0 ? headers[0].text ?? headers[0].headline : undefined}
    >
      <>
        { name && product && (
          <div className="row form-product-details">
            <div className="col-16 col-sm-12 col-md-16 col-lg-12">
              <dl className="detail-list">
                <dt>Artikel</dt>
                <dd>{name}</dd>
                <dt>{t`product_sku_label`}</dt>
                <dd>{product.sku}</dd>
                <dt>{t`product_manufacturer_number_label`}</dt>
                <dd>{product.jr_manufacturer_number}</dd>
              </dl>
            </div>
            {product.small_image?.url && (
              <div className="col-16 col-sm-4 col-md-16 col-lg-4">
                <ProductImage image={product.small_image?.url} alt={product.small_image?.alt} width="535" height="535" />
              </div>
            )}
          </div>
        )}
        <ContactFormDetective
          locale={locale}
          product={product}
          name={name}
          privacyLink={pagePrivacy.fullUrlPath}
        />
      </>
    </FormLayout>
  );
};

export default Detective;

Detective.defaultProps = {
  location: {},
};

Detective.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      product: PropTypes.objectOf(PropTypes.any),
      name: PropTypes.string,
    }),
  }),
};

export const query = graphql`
query detectiveQuery($url: String, $lang: String) {
    datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        metaCanonical
        metaRobots
    }
    datoCmsConfig(locale: {eq: $lang}) {
        pagePrivacy {
            fullUrlPath
        }
    }
}`;
